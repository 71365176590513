import React from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Axios from "axios";
import Tick from "./tick.png";
import { Map, Marker, GoogleApiWrapper } from "google-maps-react";

import { Form, Col, Button, Container, Row, Nav, Modal } from "react-bootstrap";
const HEIGHT = window.innerHeight - 200;
const MAP_SIZE = HEIGHT * 0.6;

const MERCHANT_TYPES = [
  "Restaurant",
  "Cafe",
  "Bakery",
  "Sweets Shop",
  "Pharmacy",
  "Super Market",
  "Grocery",
  "Coffee",
  "Supplements",
  "Pet's store",
  "Laundry",
  "Mobile",
  "Electronic",
  "Service",
  "Digital",
  "Others",
];

const TRANS = {
  English: {
    //Inside Website's Text
    merchant_type: "Work's specility",
    merchant_name: "Commercial Name",
    merchant_city: "City",
    merchant_address: "Address",
    merchant_phone: "Phone Number",
    merchant_email: "Email Address (optional)",
    merchant_message: "Write a Message (optional)",
    chooseOption: "Choose an option...",
    welcomeMessage: "Join Lezzoo!",
    instructionMessage: "Please fill in the form then press submit",
    thankyouMessage: `Thank you for contacting Lezzoo,
      our commercial department will follow up with you within 72 hours`,
    submit: "Submit",
    terms:
      "By clicking “Submit,” you agree to Lezzoo General Terms and Conditions and acknowledge you have read the Privacy Policy.",
    //Merchant Types
    Restaurant: "Restaurant",
    Cafe: "Cafe",
    Bakery: "Bakery",
    "Sweets Shop": "Sweets Shop",
    Pharmacy: "Pharmacy",
    "Super Market": "Super Market",
    Grocery: "Grocery",
    Coffee: "Coffee",
    Supplements: "Supplements",
    "Pet's store": "Pet's store",
    Laundry: "Laundry",
    Mobile: "Mobile",
    Electronic: "Electronic",
    Service: "Service",
    Digital: "Digital",
    Others: "Others",
    //Cities
    Erbil: "Erbil",
    Sulaymaniyah: "Sulaymaniyah",
    Duhok: "Duhok",
    Massif: "Massif",
    Basrah: "Basrah",
    Baghdad: "Baghdad",
    Kirkuk: "Kirkuk",
    Halabja: "Halabja",
    Zakho: "Zakho",
    Shaqlawa: "Shaqlawa",
    Akre: "Akre",
    Koye: "Koye",
    Soran: "Soran",
    Mousl: "Mousl",
    Rumadi: "Rumadi",
    Faluja: "Al-Fallujah",
    Ranya: "Ranya",
    Khanaqin: "Khanaqin",
    Qaladze: "Qaladze",
    Said_Sadq: "Said Sadq",
    Chwarqurna: "Chwarqurna",
    Khalifan: "Khalifan",
    Kalar: "Kalar",
    Sharazwr: "Sharazwr",
    Chamchamal: "Chamchamal",
    Anbar: "Anbar",
    Diyala: "Diyala",
    Muthannia: "Muthannia",
    Diwaniyah: "Diwaniyah",
    Najaf: "Najaf",
    Babil: "Babil",
    Diqar: "Diqar",
    Karbala: "Karbala",
    Maysan: "Maysan",
    Ninawa: "Ninawa",
    Salaheddin: "Salaheddin",
    Wasit: "Wasit"
  },
  "Kurdish Sorani": {
    //Inside Website's text
    merchant_type: "جۆری فرۆشگا",
    merchant_name: "ناوی فرۆشگا",
    merchant_city: "شار",
    merchant_address: "ناوونیشانی فرۆشگا",
    merchant_phone: "ژمارەی مۆبایل",
    merchant_email: "ئیمەیڵ",
    merchant_message: "تێبینی",
    chooseOption: "دانەیەک هەڵبژێرە",
    welcomeMessage: "!بەژداربە لەگەڵ لەزوو",
    instructionMessage: "تکایە ئەمانەی خوارەوە پڕ بکەرەوە",
    thankyouMessage:
      "سوپاس بۆ تۆمارکردنی فرۆشگایەکەت، بەشی فرۆشتنی لەزوو پەیوەندیت پێوەدەکات لە ماوەی ٧٢ کاتژمێر",
    submit: "تۆماری بکە",
    terms:
      "ە پەنجەنان بە ناردن تۆ رازیت بە مەرجە گشتیەکان و بڕیارەکانی لەزوو کەوا خوێندوتەوە.",
    //Merchant Type's
    Restaurant: "چێشتخانە",
    Cafe: "کافێ",
    Bakery: "نانەوا و سەموونخانە",
    "Sweets Shop": "شیرنەمەنی",
    Pharmacy: "دەرمانخانە",
    "Super Market": "سوپەرمارکێت",
    Grocery: "سەوزە و میوە",
    Coffee: "قاوە",
    Supplements: "تەواوکەری خۆراکی",
    "Pet's store": "کەلو پەل و خواردنی ئاژەڵ",
    Laundry: "جلشۆر و ئوتوو",
    Mobile: "مۆبایل",
    Electronic: "ئەلیکترۆنی",
    Service: "خزمەتگوزاری",
    Digital: "دیجیتاڵی",
    Others: "بابەتی تر",
    //Cities
    Erbil: "هەولێر",
    Sulaymaniyah: "سلێمانی",
    Duhok: "دهۆک",
    Massif: "مەسیف",
    Basrah: "بەسڕە",
    Baghdad: "بەغدا",
    Kirkuk: "کەرکووک",
    Halabja: "ھەڵەبجە",
    Zakho: "زاخۆ",
    Shaqlawa: "شەقڵاوە",
    Akre: "ئاکرێ",
    Koye: "کۆیە",
    Soran: "سۆران",
    Mousl: "موسڵ",
    Rumadi: "ڕومادی",
    Faluja: "فەلوجە",
    Ranya: "ڕانیە",
    Khanaqin: "خانەقین",
    Qaladze: "قلادزي",
    Said_Sadq: "سيد صادق",
    Chwarqurna: "چوارقورنە",
    Khalifan: "خليفان",
    Kalar: "كلار",
    Sharazwr: "شاره‌زوور",
    Chamchamal: "جمجمال",
    Anbar: "الأنبار",
    Diyala: "ديالى",
    Muthannia: "المثنى",
    Diwaniyah: "الديوانية",
    Najaf: "النجف",
    Babil: "بابل",
    Diqar: "ذي قار",
    Karbala: "كربلاء",
    Maysan: "ميسان",
    Ninawa: "نينوى",
    Salaheddin: "صلاح الدين",
    Wasit: "واسط"
  },
  "Kurdish Badani": {
    //Inside Website's text
    merchant_type: "جۆری فرۆشگا",
    merchant_name: "ناوی فرۆشگا",
    merchant_city: "شار",
    merchant_address: "ناوونیشانی فرۆشگا",
    merchant_phone: "ژمارەی مۆبایل",
    merchant_email: "ئیمەیڵ",
    merchant_message: "تێبینی",
    chooseOption: "دانەیەک هەڵبژێرە",
    welcomeMessage: "!بەژداربە لەگەڵ لەزوو",
    instructionMessage: "تکایە ئەمانەی خوارەوە پڕ بکەرەوە",
    thankyouMessage:
      "سوپاس بۆ تۆمارکردنی فرۆشگایەکەت، بەشی فرۆشتنی لەزوو پەیوەندیت پێوەدەکات لە ماوەی ٧٢ کاتژمێر",
    submit: "تۆماری بکە",
    terms:
      "ە پەنجەنان بە ناردن تۆ رازیت بە مەرجە گشتیەکان و بڕیارەکانی لەزوو کەوا خوێندوتەوە.",
    //Merchant Type's
    Restaurant: "چێشتخانە",
    Cafe: "کافێ",
    Bakery: "نانەوا و سەموونخانە",
    "Sweets Shop": "شیرنەمەنی",
    Pharmacy: "دەرمانخانە",
    "Super Market": "سوپەرمارکێت",
    Grocery: "سەوزە و میوە",
    Coffee: "قاوە",
    Supplements: "تەواوکەری خۆراکی",
    "Pet's store": "کەلو پەل و خواردنی ئاژەڵ",
    Laundry: "جلشۆر و ئوتوو",
    Mobile: "مۆبایل",
    Electronic: "ئەلیکترۆنی",
    Service: "خزمەتگوزاری",
    Digital: "دیجیتاڵی",
    Others: "بابەتی تر",
    //Cities
    Erbil: "هەولێر",
    Sulaymaniyah: "سلێمانی",
    Duhok: "دهۆک",
    Massif: "مەسیف",
    Basrah: "بەسڕە",
    Baghdad: "بەغدا",
    Kirkuk: "کەرکووک",
    Halabja: "ھەڵەبجە",
    Zakho: "زاخۆ",
    Shaqlawa: "شەقڵاوە",
    Akre: "ئاکرێ",
    Koye: "کۆیە",
    Soran: "سۆران",
    Mousl: "موسڵ",
    Rumadi: "ڕومادی",
    Faluja: "فەلوجە",
    Ranya: "ڕانیە",
    Khanaqin: "خانەقین",
    Qaladze: "قلادزي",
    Said_Sadq: "سيد صادق",
    Chwarqurna: "چوارقورنە",
    Khalifan: "خليفان",
    Kalar: "كلار",
    Sharazwr: "شاره‌زوور",
    Chamchamal: "جمجمال",
    Anbar: "الأنبار",
    Diyala: "ديالى",
    Muthannia: "المثنى",
    Diwaniyah: "الديوانية",
    Najaf: "النجف",
    Babil: "بابل",
    Diqar: "ذي قار",
    Karbala: "كربلاء",
    Maysan: "ميسان",
    Ninawa: "نينوى",
    Salaheddin: "صلاح الدين",
    Wasit: "واسط"
  },
  Arabic: {
    //Inside Website's Text
    merchant_type: "تخصص العمل",
    merchant_name: "الاسم التجاري",
    merchant_city: "المدينة",
    merchant_address: "العنوان",
    merchant_phone: "رقم الهاتف",
    merchant_email: "(عنوان البريد الالكتروني (اختياري",
    merchant_message: "(اكتب رسالة (اختياري",
    chooseOption: "حدد خيار",
    welcomeMessage: "!انظم إلى ليزو",
    instructionMessage: "يرجى ملء النموذج ثم الضغط على إرسال",
    thankyouMessage:
      "شكراً لاتصالك بـ ليزو، سيتابع القسم التجاري معك خلال 72 ساعة.",
    submit: "تقديم",
    terms:
      'بالنقر على "تقديم" ، فإنك توافق على الشروط والأحكام العامة الخاصة بـ ليزو وتقر بقراءة سياسة الخصوصية.',
    //Merchant Type's
    Restaurant: "مطعم",
    Cafe: "كافيه",
    Bakery: "مخبز",
    "Sweets Shop": "محل حلويات",
    Pharmacy: "صيدليه",
    "Super Market": "اسواق",
    Grocery: "بقالة",
    Coffee: "قهوه",
    Supplements: "مكملات غذائية",
    "Pet's store": "متجر حيوانات أليفة",
    Laundry: "مغسلة",
    Mobile: "هواتف نقالة",
    Electronic: "ئەلیکترۆنی",
    Service: "خزمەتگوزاری",
    Digital: "دیجیتاڵی",
    Others: "أخرى",
    //Cities
    Erbil: "اربيل",
    Sulaymaniyah: "السليمانية",
    Duhok: "دهوك",
    Massif: "مصیف صلاحدين",
    Basrah: "البصرة",
    Kirkuk: "كركوك",
    Halabja: "حلبجة",
    Zakho: "زاخو",
    Shaqlawa: "شقلاوة",
    Akre: "أكري",
    Koye: "كوي",
    Soran: "سوران",
    Mousl: "الموصل",
    Rumadi: "الرمادي",
    Faluja: "الفلوجه",
    Ranya: "رانیە",
    Khanaqin: "خانقين",
    Baghdad: "بغداد",
    Qaladze: "قلادزي",
    Said_Sadq: "سيد صادق",
    Chwarqurna: "چوارقورنە",
    Khalifan: "خليفان",
    Kalar: "كلار",
    Sharazwr: "شاره‌زوور",
    Chamchamal: "جمجمال",
    Anbar: "الأنبار",
    Diyala: "ديالى",
    Muthannia: "المثنى",
    Diwaniyah: "الديوانية",
    Najaf: "النجف",
    Babil: "بابل",
    Diqar: "ذي قار",
    Karbala: "كربلاء",
    Maysan: "ميسان",
    Ninawa: "نينوى",
    Salaheddin: "صلاح الدين",
    Wasit: "واسط"
  },
};

const PLACEHOLDER = {
  English: {
    merchant_name: "What's the name of your business?",
    merchant_address: "Where is your business' location?",
    merchant_phone: "What's your mobile number?",
    merchant_email: "What's your email Address?",
    merchant_message: "",
    others: "Kindly specify",
  },

  "Kurdish Sorani": {
    merchant_name: "ناوی کارەکەت چیە ؟",
    merchant_address: "شوێنی کارەکەت` لە کوێیە ؟",
    merchant_phone: "ژمارەی مۆبایلەکەت چەندە ؟",
    merchant_email: "ناونیشانی ئیمێلەکەت چیە ؟",
    merchant_message: "",
    others: "تکایە دیاری بکە",
  },
  "Kurdish Badani": {
    merchant_name: "ناوی کارەکەت چیە ؟",
    merchant_address: "شوێنی کارەکەت` لە کوێیە ؟",
    merchant_phone: "ژمارەی مۆبایلەکەت چەندە ؟",
    merchant_email: "ناونیشانی ئیمێلەکەت چیە ؟",
    merchant_message: "",
    others: "تکایە دیاری بکە",
  },
  Arabic: {
    merchant_name: "ما اسم عملك؟",
    merchant_address: "أين موقع عملك؟",
    merchant_phone: "ما هو رقم هاتفك المحمول؟",
    merchant_email: "ما هو عنوان بريدك الالكتروني؟",
    merchant_message: "",
    others: "يرجى التحديد",
  },
};
const CITY_COORDINATES = {
  Erbil: {
    longitude: 44.012006,
    latitude: 36.190854,
  },
  Sulaymaniyah: {
    latitude: 35.55526908406638,
    longitude: 45.43534127504543,
  },
  Duhok: {
    latitude: 36.863315671575876,
    longitude: 42.989012871982055,
  },
  Zakho: {
    latitude: 37.1442,
    longitude: 42.6872,
  },
  Baghdad: {
    latitude: 33.3057995,
    longitude: 44.3483193,
  },
  Massif: {
    latitude: 36.378913,
    longitude: 44.203725,
  },
  Basrah: {
    longitude: 47.783054,
    latitude: 30.535642,
  },
  Kirkuk: {
    latitude: 35.4681,
    longitude: 44.3922,
  },
  Shaqlawa: {
    latitude: 36.409856,
    longitude: 44.316383,
  },
  Akre: {
    latitude: 36.738185,
    longitude: 43.880895,
  },
  Koye: {
    latitude: 36.081854,
    longitude: 44.62994,
  },
  Halabja: {
    latitude: 35.1778,
    longitude: 45.9869,
  },
  Soran: {
    latitude: 36.6533,
    longitude: 44.5433,
  },
  Mousl: {
    latitude: 36.342352,
    longitude: 43.1378974,
  },
  Rumadi: {
    latitude: 33.444616,
    longitude: 43.284448,
  },
  Faluja: {
    latitude: 33.338966,
    longitude: 43.795795,
  },
  Ranya: {
    latitude: 36.255214,
    longitude: 44.883402,
  },
  Qaladze: {
    latitude: 36.181626829,
    longitude: 45.128887612,
  },
  Said_Sadq: {
    latitude: 35.354125,
    longitude: 45.865665953,
  },
  Chwarqurna: {
    latitude: 36.205197,
    longitude: 44.823079,
  },
  Khanaqin: {
    latitude: 34.347578,
    longitude: 45.390615,
  },
  Khalifan: {
    latitude: 36.588908,
    longitude: 44.392372,
  },
  Kalar: {
    latitude: 34.62911961,
    longitude: 45.31517812,
  },
  Sharazwr: {
    latitude: 35.315972,
    longitude: 45.68349809,
  },
  Chamchamal: {
    latitude: 35.52292238,
    longitude: 44.834344804,
  },
  Anbar: {
    latitude: 33.4261607615,
    longitude: 43.3023329055,
  },
  Diyala: {
    latitude: 33.7569946,
    longitude: 44.6511217,
  },
  Muthannia: {
    latitude: 31.3084513,
    longitude: 45.2906765,
  },
  Diwaniyah: {
    latitude: 31.982851783,
    longitude: 44.915890425,
  },
  Najaf: {
    latitude: 32.0029825,
    longitude: 44.35611753,
  },
  Babil: {
    latitude: 32.4929137,
    longitude: 44.4320705,
  },
  Diqar: {
    latitude: 31.04737279,
    longitude: 46.26124452,
  },
  Karbala: {
    latitude: 32.6033444,
    longitude: 44.0250033,
  },
  Maysan: {
    latitude: 31.8477788,
    longitude: 47.1269559,
  },
  Ninawa: {
    latitude: 35.757361781,
    longitude: 42.246930285,
  },
  Salaheddin: {
    latitude: 34.61476884,
    longitude: 43.67337956,
  },
  Wasit: {
    latitude: 32.4978479,
    longitude: 45.8312558
  }
};
const LANGUAGES = ["English", "Kurdish Sorani", "Kurdish Badani", "Arabic"];

const MERCHANT_CITIES = [
  "Erbil",
  "Sulaymaniyah",
  "Duhok",
  "Zakho",
  "Baghdad",
  "Massif",
  "Basrah",
  "Kirkuk",
  "Shaqlawa",
  "Akre",
  "Koye",
  "Halabja",
  "Soran",
  "Mousl",
  "Rumadi",
  "Faluja",
  "Ranya",
  "Qaladze",
  "Said_Sadq",
  "Chwarqurna",
  "Khanaqin",
  "Khalifan",
  "Kalar",
  "Sharazwr",
  "Chamchamal",
  "Anbar",
  "Diyala",
  "Muthannia",
  "Diwaniyah",
  "Najaf",
  "Babil",
  "Diqar",
  "Karbala",
  "Maysan",
  "Ninawa",
  "Salaheddin",
  "Wasit"
];

const containerStyle = {
  position: "relative",
  width: "100%",
  height: MAP_SIZE,
};

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: "form",
      current_language: "English",
      merchant_type: -1,
      merchant_name: "",
      merchant_city: -1,
      merchant_address: "",
      merchant_phone: "",
      merchant_email: "",
      merchant_message: "",
      merchant_coordinates: { longitude: 44.012006, latitude: 36.190854 },
      mounted: false,
      submitButton: true,
      loadModal: false,
      selectedCity: "Erbil",
      othersShow: false,
      merchant_type_description: "",
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  componentDidMount() {
    this.setState({
      mounted: true,
    });
  }

  handleSubmit = () => {
    if (!(this.state.merchant_type > -1)) {
      alert("Please choose a merchant type!");
    } else if (!(this.state.merchant_city > -1)) {
      alert("Please choose a merchant city!");
    } else if (!(this.state.merchant_name.length > 2)) {
      alert("Please type the merchant name!");
    } else if (this.state.merchant_name.match(/[0-9]+$/)) {
      alert("Name contains only alphabets");
    } else if (!(this.state.merchant_address.length > 4)) {
      alert("Please type the merchant address!");
    } else if (!(this.state.merchant_phone.length >= 10)) {
      alert(
        "Please type a correct phone number! Ex: 07x01234567 or +9647x01234567."
      );
    } else {
      this.setState({
        page: "loading",
      });
      Axios.post("https://production.backendapi.lezzoodevs.com/service/join", {
        merchant_type: MERCHANT_TYPES[this.state.merchant_type],
        merchant_type_description: this.state.merchant_type_description,
        merchant_name: this.state.merchant_name,
        merchant_city: MERCHANT_CITIES[this.state.merchant_city],
        merchant_address: this.state.merchant_address,
        merchant_phone: this.state.merchant_phone,
        merchant_email: this.state.merchant_email,
        merchant_message: this.state.merchant_message,
        merchant_longitude: this.state.merchant_coordinates.longitude,
        merchant_latitude: this.state.merchant_coordinates.latitude,
      })
        .then((res) => {
          console.log("res", res);
          this.setState({
            page: "success",
          });
        })
        .catch((error) => {
          console.log("error", error);
          alert("Error occured, please try again later!");
          this.setState({
            page: "form",
          });
        });
    }
  };

  renderSelectInput(value, list = [], cityChanged) {
    var { current_language, selectedCity, merchant_coordinates } = this.state;
    var coordinates = { longitude: 0, latitude: 0 };
    merchant_coordinates = cityChanged ? coordinates : merchant_coordinates;
    return (
      <Form.Group
        style={{
          alignContent: "flex-start",
          alignItems: "flex-start",
          justifyItems: "flex-start",
        }}
        controlId={value}
      >
        <Form.Label>{TRANS[current_language][value]}</Form.Label>
        <Form.Control
          as="select"
          value={this.state[value]}
          onChange={(event) => {
            this.setState({
              [value]: event.target.value,
              selectedCity: cityChanged
                ? MERCHANT_CITIES[event.target.value]
                : selectedCity,
              merchant_coordinates,
            });
          }}
        >
          <option value={-1}>{TRANS[current_language]["chooseOption"]}</option>

          {list.map((value, index) => (
            <option key={index} value={index}>
              {TRANS[current_language][value]}
            </option>
          ))}
        </Form.Control>
      </Form.Group>
    );
  }

  renderTextInput(value, type, isTextArea) {
    const { current_language } = this.state;

    return (
      <Form.Group
        style={{
          alignContent: "flex-start",
          alignItems: "flex-start",
          justifyItems: "flex-start",
        }}
        controlId={value}
      >
        <Form.Label>{TRANS[current_language][value]}</Form.Label>
        <Form.Control
          as={isTextArea ? "textarea" : "input"}
          value={this.state[value]}
          placeholder={PLACEHOLDER[current_language][value]}
          type={type}
          onChange={(event) => {
            this.setState({
              [value]: event.target.value,
            });
          }}
        />
      </Form.Group>
    );
  }

  renderTitle() {
    const { current_language } = this.state;

    return (
      <h3>
        <img
          alt={"Lezzoo"}
          style={{ height: 60, marginRight: 20 }}
          src={"https://lezzoo.com/images/Group.png"}
        />
        <br></br>
        {TRANS[current_language]["welcomeMessage"]}
      </h3>
    );
  }

  renderLanguageSelection() {
    const { current_language } = this.state;

    return LANGUAGES.map((value, index) => (
      <Button
        key={index}
        variant={current_language === value ? "primary" : "default"}
        style={{ marginRight: 10, marginLeft: 10, marginTop: 10 }}
        onClick={() => {
          if (current_language !== value.code) {
            this.setState({
              current_language: value,
            });
          }
        }}
      >
        {value}
      </Button>
    ));
  }

  renderFormTitle() {
    const { current_language } = this.state;

    return (
      <p
        style={{
          marginTop: 20,
          marginBottom: 5,

          alignContent: "center",
          alignItems: "center",
          justifyContent: "center",
          justifyItems: "center",
          textAlign: "center",
        }}
      >
        {TRANS[current_language]["instructionMessage"]}
      </p>
    );
  }

  handleChange = (e) => {
    const { submitButton } = this.state;
    this.setState({
      submitButton: !submitButton,
    });
  };
  handleClick = (e) => {
    this.setState({
      loadModal: true,
    });
  };
  renderTermsAndConditions() {
    const { current_language } = this.state;
    return (
      <Form.Group controlId="formBasicCheckbox">
        <Form.Check
          type="checkbox"
          label={
            <Nav.Link onClick={this.handleClick} style={{ marginTop: "-10px" }}>
              {TRANS[current_language]["terms"]}
            </Nav.Link>
          }
          onChange={this.handleChange}
        />
      </Form.Group>
    );
  }

  handleClose = () => {
    this.setState({
      loadModal: false,
    });
  };
  renderModal() {
    const { loadModal } = this.state;

    return (
      <Modal show={loadModal} onHide={this.handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Terms and Conditions</Modal.Title>
        </Modal.Header>
        <Modal.Body>Terms and conditions text</Modal.Body>
        {/* <Modal.Footer>
					<Button variant="secondary" onClick={this.handleClose}>
						Close
          </Button>
					<Button variant="primary" onClick={this.handleClose}>
						Save Changes
          </Button>
				</Modal.Footer> */}
      </Modal>
    );
  }
  renderForm() {
    const { current_language, merchant_type, merchant_type_description } =
      this.state;

    return (
      <Container fluid="lg">
        <Row>
          <Col
            style={{
              marginTop: 15,
              marginBottom: 30,

              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            {this.renderTitle()}
            {this.renderLanguageSelection()}
            {this.renderFormTitle()}
          </Col>
        </Row>

        <Row>
          <Col sm={12} md={1} lg={3} />
          <Col sm={12} md={10} lg={6}>
            <Form
              style={{
                alignContent: "flex-start",
                alignItems: "flex-start",
                justifyItems: "flex-start",
              }}
              onSubmit={this.handleSubmit}
            >
              {this.renderSelectInput("merchant_type", MERCHANT_TYPES, false)}
              {parseInt(merchant_type) === 12 && (
                <Form.Group
                  style={{
                    alignContent: "flex-start",
                    alignItems: "flex-start",
                    justifyItems: "flex-start",
                  }}
                >
                  <Form.Control
                    value={merchant_type_description}
                    placeholder={PLACEHOLDER[current_language]["others"]}
                    onChange={(event) => {
                      this.setState({
                        merchant_type_description: event.target.value,
                      });
                    }}
                  />
                </Form.Group>
              )}

              {this.renderTextInput("merchant_name", "text", false)}
              {this.renderSelectInput("merchant_city", MERCHANT_CITIES, true)}
              {this.renderTextInput("merchant_address", "text", false)}

              {this.renderMap()}
              <br />
              {this.renderTextInput("merchant_phone", "text", false)}
              {this.renderTextInput("merchant_email", "email", false)}
              {this.renderTextInput("merchant_message", "text", true)}
              {/* {this.renderTermsAndConditions()} */}
              {/* <Button variant="primary" onClick={this.handleSubmit} style={{ marginLeft: '40%' }} disabled={this.state.submitButton}> */}
              <Button
                variant="primary"
                onClick={this.handleSubmit}
                style={{ marginLeft: "40%" }}
              >
                {TRANS[current_language]["submit"]}
              </Button>
              <br />
              <br />
              <br />
              {this.renderModal()}
            </Form>
          </Col>
        </Row>
      </Container>
    );
  }

  renderLoading() {
    return (
      <Container fluid="lg">
        <Row>
          <Col
            style={{
              marginTop: 15,
              marginBottom: 30,

              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            {this.renderTitle()}
          </Col>
        </Row>
        <Row>
          <Col
            style={{
              marginTop: 15,
              marginBottom: 30,

              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            <h1 style={{ alignSelf: "center" }}>Loading...</h1>
          </Col>
        </Row>
      </Container>
    );
  }

  renderSucccess() {
    const { current_language } = this.state;

    return (
      <Container fluid="lg">
        <Row>
          <Col
            style={{
              marginTop: 15,
              marginBottom: 30,

              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            {this.renderTitle()}
          </Col>
        </Row>

        <Row>
          <Col
            style={{
              marginTop: 50,
              marginBottom: 30,

              alignContent: "center",
              alignItems: "center",
              justifyContent: "center",
              justifyItems: "center",
              textAlign: "center",
            }}
          >
            <img
              alt={"Success"}
              style={{ height: 40, marginBottom: 20 }}
              src={Tick}
            />
            <p style={{ alignSelf: "center", fontWeight: "bold" }}>
              {TRANS[current_language]["thankyouMessage"]}
            </p>
          </Col>
        </Row>
      </Container>
    );
  }
  render() {
    const { page } = this.state;

    switch (page) {
      case "form":
        return this.renderForm();
      case "loading":
        return this.renderLoading();
      case "success":
        return this.renderSucccess();
      default:
        return this.renderForm();
    }
  }

  onInfoWindowClose() {
    console.log("working");
  }
  onMapClicked = (mapProps, map, clickEvent) => {
    var merchant_coordinates = {
      latitude: clickEvent.latLng.lat(),
      longitude: clickEvent.latLng.lng(),
    };

    this.setState({
      merchant_coordinates,
    });
  };

  renderMap() {
    const { merchant_coordinates, selectedCity } = this.state;
    var lat =
      merchant_coordinates.latitude > 0
        ? merchant_coordinates.latitude
        : CITY_COORDINATES[selectedCity]["latitude"];

    var lng =
      merchant_coordinates.longitude > 0
        ? merchant_coordinates.longitude
        : CITY_COORDINATES[selectedCity]["longitude"];

    return (
      <Map
        google={this.props.google}
        zoom={14}
        containerStyle={containerStyle}
        initialCenter={{ lat, lng }}
        center={{ lat, lng }}
        onClick={this.onMapClicked}
      >
        <Marker name={"Current location"} position={{ lat, lng }} />
        {/* <InfoWindow onClose={this.onInfoWindowClose}>
					<div>
						<h1>{selectedCity}</h1>
					</div>
				</InfoWindow> */}
      </Map>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyBVuV8CTPshnhKzV8iVco_9dzgbJAK0yoA",
  libraries: ["places", "visualization"],
  // LoadingContainer: Loading
})(App);
